import React from "react";
import {Col, Row} from "react-bootstrap";
import * as styles from "./recruitmentSection.module.scss"
import {LinkButton, LinkButtonOutline} from "../../linkButton/linkButton";
import JSONData from "../../../../content/recruitmentSectionData.json"
import CheckSVG from "../../../svg/check.svg"

export default function RecruitmentSection() {

    return <Row>
        <Col xs={{span:12}} md={{offset:1,span:10}} lg={{span: 6, offset: 1}}>
            <h1 className={styles.header}>
                {JSONData.title}
            </h1>
            <div className={styles.text}>
                {JSONData.text}
            </div>
            <div className={styles.buttonDesktop}>
                <LinkButton to="/services">
                    Our services
                </LinkButton>
            </div>
        </Col>
        <Col xs={{span:12}} md={{offset:1,span:10}} lg={{span: 4}}>
            {JSONData.listContent.map((data: any, idx: number) => <ListItem key={idx} text={data.item}/>)}
            <div className={styles.buttonMobile}>
                <LinkButton to="/services">
                    Our services
                </LinkButton>
            </div>
        </Col>
    </Row>
}

function ListItem(props: { text: string }) {
    return <div className={styles.listItemWrapper}>
        <div>
            <CheckSVG/>
        </div>
        <div className={styles.listItemText}>
            {props.text}
        </div>
    </div>
}
