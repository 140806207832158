import React from "react"
import * as styles from "./ourServices.module.scss"
import RecruitmentSection from "./recruitmentSection/recruitmentSection";
import ExpertsSection from "./expertsSection/expertsSection";
import DiversitySection from "./diversitySection/diversitySection";

export default function OurServices() {

    return <div className={styles.wrapper}>
        <RecruitmentSection/>
        <div className={styles.verticalBreak}/>
        <ExpertsSection/>
        <div className={styles.verticalBreak}/>
        <DiversitySection/>
    </div>
}
