// extracted by mini-css-extract-plugin
export var animationContainer = "home-hero-module--animationContainer--8e23a";
export var backgroundAnimation = "home-hero-module--backgroundAnimation--76e38";
export var buttons = "home-hero-module--buttons--90fa3";
export var card = "home-hero-module--card--cbd2c";
export var clientLogo = "home-hero-module--client-logo--256f3";
export var clients = "home-hero-module--clients--f14e5";
export var clientsTitle = "home-hero-module--clientsTitle--04907";
export var image = "home-hero-module--image--f0bfd";
export var image1 = "home-hero-module--image1--2827c";
export var image2 = "home-hero-module--image2--e464e";
export var image3 = "home-hero-module--image3--3c7b9";
export var image4 = "home-hero-module--image4--e3d1e";
export var imageWrapper = "home-hero-module--image-wrapper--da34f";
export var images = "home-hero-module--images--031ac";
export var imagesPlaceholder = "home-hero-module--imagesPlaceholder--0088e";
export var subtext = "home-hero-module--subtext--e7011";
export var title = "home-hero-module--title--bb4ee";
export var wrapper = "home-hero-module--wrapper--cd27e";