import * as React from "react"
import {Helmet} from "react-helmet"
import Header from "../components/header/header";
import HomeHero from "../components/home-hero/home-hero";
import Layout from "../components/layout/layout";
import OurServices from "../components/ourServices/ourServices";
import Footer from "../components/footer/footer";
import OurWork from "../components/ourWork/ourWork";
import Cta from "../components/cta/cta";
import LatestNews from "../components/latestNews/latestNews";

const IndexPage = () => {
    return (<>
        <Helmet>
            <meta charSet="utf-8"/>
            <html lang="en"/>
            <title>Recoded Recruitment</title>
            <meta name="description"
                  content="Hiring top talent in tech just got easy. Partner with us for expert tech industry recruitment. Choose from our Search, Contingent, or Embedded services for tailored solutions to match your needs."/>
            <meta name="theme-color" content="#F6F4FB"></meta>
        </Helmet>
        <Layout header={<Header transparent={true}/>}
                footer={<Footer/>}>
            <HomeHero/>
            <OurServices/>
            <OurWork/>
            {/**<div style={{ height: '140px' }}></div>**/}
            {/**<LatestNews title={"All the latest news & insights"} />**/}
            <Cta title="Start hiring today" buttonText="Book a call" buttonTarget="/book-a-call/"  />
        </Layout>
    </>);
}

export default IndexPage
